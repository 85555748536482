import React from "react"
import { ThemeProvider } from "styled-components"
import { GlobalStyle, theme } from "../utils/theme"

import {
  Navigation,
  ContactSection,
  FooterSection,
} from "../sections/index.sections"
import StoreProvider from "../store/StoreProvider"
const ContactPage = () => {
  return (
    <>
      <GlobalStyle />
      <StoreProvider>
        <ThemeProvider theme={theme}>
          <div className="page-wrapper">
            <Navigation />
            <main style={{ minHeight: "90vh" }}>
              <ContactSection />
            </main>
            <FooterSection />
          </div>
        </ThemeProvider>
      </StoreProvider>
    </>
  )
}

export default ContactPage
